import React, {useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardDeck,
    // CardGroup,
    // CardColumns,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    // CardText,
    // CardImg,
    // FormGroup,
    // Label,
    // Input,
    // FormText,
    // Table,

} from "reactstrap";

import {useTranslation} from "react-i18next";

import "../../assets/css/kap-style.css"

import Image360View from "../Scan/Image360View"
import MeasurementsEditor from "../Scan/MeasurementsEditor";
import BabyLon3DViewer from "../Scan/BabyLon3DViewer";
import ScanInfo from "./ScanInfo";

// Import everything needed to use the `useQuery` hook
import {useQuery, gql} from '@apollo/client';

const GET_PRODUCT = gql`
    query GetProduct($id: ID!) {
        productById(id: $id) {
            id
            scans {
                id
                preset
                started
                finished
                mode

                latestRevision {
                    id
                    tags
                    position
                    user {
                        name
                        email
                    }

                    measurement {
                        length
                        height
                        width
                        weightCalc
                    }

                    threeSixtyImagePaths
                    model
                    #mediaPathsByTag(tags: "Kap_360Images", matchAll: true)
                }
            }
        }
    }
`;

function ProductDetails(props) {
    const [scanUuid, setScanUuid] = useState(null);

    const {loading, error, data} = useQuery(GET_PRODUCT, {
        variables: {id: props.match.params.uuid},
    });


    const {t, i18n} = useTranslation();

    const setScan = (uuid) => {
        setScanUuid(uuid)
    };

    if (props.match.params.uuid === ':uuid') {
        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">
                                    {t('Please select an article from the overview first. The details will be shown here.')}
                                </CardTitle>
                            </CardHeader>
                            <CardBody/>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    } else {
        if (error) {
            return (
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{t('Error')}: {error.message}</CardTitle>
                                </CardHeader>
                                <CardBody/>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        } else if (loading) {
            return (
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle
                                        tag="h4">{t('Loading')} {t('Article Details')} ...</CardTitle>
                                </CardHeader>
                                <CardBody/>
                            </Card>
                        </Col>
                    </Row>
                </div>
            )
        } else {
            // either a scan was selected or the component / page is loaded the first time
            // take the latest scan on first load
            // const img = data.productById.scans[0].latestRevision.threeSixtyImagePaths;
            // if (img === ''){
            //     console.log('a');
            // } else {
            //     console.log('b');
            // }
            // console.log(data.productById.scans[0].latestRevision.threeSixtyImagePaths);
            const scan = scanUuid ?
                data.productById.scans.find((scan) => scan.id === scanUuid) :
                data.productById.scans.slice(-1)[0];
            const img360 = data.productById.scans[0].latestRevision.threeSixtyImagePaths;
            const model3D = data.productById.scans[0].latestRevision.model;


            // console.log(model3D);

                return (
                    <div className="content product-details">
                        <CardDeck>
                            <Card>
                                <CardHeader>
                                    <h3>{t('360 View')}</h3>
                                </CardHeader>
                                <CardBody>
                                    {img360 ? <Image360View scan={scan} key={scan.id}/> :
                                        <CardTitle tag="h4">
                                            {t('No 360 Image')}
                                        </CardTitle>
                                    }

                                    {/*
                                    <div className="float-right">
                                        <p>{t('Download Product Pictures')}
                                            <Button color="primary"
                                                    className=" btn-icon btn-link "> <i className="fa fa-download" /> </Button>
                                        </p>
                                    </div>
                                */}
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <h3>{t('3D View')}</h3>
                                </CardHeader>
                                <CardBody>
                                    { model3D ? <BabyLon3DViewer scan={scan} key={scan.id}/> :
                                        <CardTitle tag="h4">
                                            {t('No 3D Model')}
                                        </CardTitle>
                                    }

                                </CardBody>
                            </Card>
                        </CardDeck>

                        <CardDeck>
                            <Card>
                                <CardHeader>
                                    <h3>{t('All Scans')}</h3>
                                </CardHeader>
                                <CardBody>
                                    <ScanInfo product={data.productById} onClick={(uuid) => setScan(uuid)}/>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <h3>{t('Measurements')}</h3>
                                </CardHeader>
                                <CardBody>
                                    <MeasurementsEditor scanRevision={scan.latestRevision} key={scan.id} />
                                </CardBody>
                            </Card>
                        </CardDeck>
                    </div>
                );

        }
    }

}

export default ProductDetails;