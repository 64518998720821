import React from 'react'

import {Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Form, Input, Row} from "reactstrap";
import {useTranslation} from 'react-i18next';

const ProductsFilter = ({onClickFilterCallback, defaultKeyword}) => {
    const [keyword, setKeyword] = React.useState(defaultKeyword)
    const onKeywordChange = (e) => {
        setKeyword(e.target.value)
    }
    const onClickSearch = () => {
        onClickFilterCallback(keyword)
    }
    const {t} = useTranslation();

    return (
        <Card style={{"box-shadow": "0 1px 20px 0px rgba(0, 0, 0, 0.5)"}}>
            <CardBody>
                <CardTitle tag="h5">
                    {t('Search Options')}
                </CardTitle>
                <CardSubtitle
                    className="mb-2 text-muted"
                    tag="h6"
                >
                    {t('for filtering the results')}
                </CardSubtitle>
                <CardText>
                    {t('Search for an article number')}
                </CardText>
                <Form onSubmit={(e) => { e.preventDefault(); onClickFilterCallback(keyword); }}>
                    <Row className="row-cols-lg-auto g-3 align-items-center">
                        <Col md={3}>

                            <Input
                                value={keyword}
                                onChange={onKeywordChange}
                                type="text"
                                placeholder={t('Article Number')}
                            />

                        </Col>
                        <Col>
                            <Button className="row-cols-lg-auto g-3 align-items-center"
                                    onClick={onClickSearch}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        </Card>


    )
}

export default ProductsFilter;