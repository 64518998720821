import React from 'react';
import { Route, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin/Admin.js";
import {components} from "react-select";

//Here is logout function passed down as props. from App to GuardedRoute to Route and all the way down to AdminNavbar.
const GuardedRoute = ({ component: Component, auth, logout,lang,...rest }) => {
    console.log("auth:" + auth);

    return(
        <Route { ...rest} render={(props) => (
            auth === true
                ? <AdminLayout {...props} logout={logout}><Component {...props} /></AdminLayout>
                : <Redirect to='/' />
        )} />
    )
}

export default GuardedRoute;