import React, {useState, useEffect} from 'react';

import {useTranslation} from "react-i18next";

import {Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";

import Tridi from 'react-tridi';
import 'react-tridi/dist/index.css';

import {baseURL} from '../../services/apiService'

function Image360View(props) {

    const [isAutoPlayRunning, setIsAutoPlayRunning] = useState(false);

    const {t, i18n} = useTranslation();

    const imageURLs = () => (props.scan.latestRevision.threeSixtyImagePaths.map((path) => `${baseURL}cache/` + path));

    if (!props.scan.latestRevision.threeSixtyImagePaths) {
        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">{t('Loading')} 360° {t('Images')} ...</CardTitle>
                            </CardHeader>
                            <CardBody/>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    } else {
        //rendering images and actions onclick
        return (
            <div className={"tridi-container"}>
                <Tridi
                    images={imageURLs()}
                    // location="/images"
                    // format="jpg"
                    // count="16"
                    // onFrameChange={frameChangeHandler}
                    autoplaySpeed={70}
                    onAutoplayStart={() => setIsAutoPlayRunning(true)}
                    onAutoplayStop={() => setIsAutoPlayRunning(false)}
                    // onRecordStart={recordStartHandler}
                    // onRecordStop={recordStopHandler}
                    // onPinClick={pinClickHandler}
                    // inverse
                    showControlBar
                />

            </div>
        );
    }

}

export default Image360View;
