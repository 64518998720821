import React, {useEffect, useState} from "react";
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, Input, Row, Table,
} from "reactstrap";
import "../../assets/css/kap-style.css"

import {useTranslation} from "react-i18next";


function MeasurementsEditor(props) {

    const [savedDimensions, setSavedDimensions] = useState({
        width: null, height: null, length: null, weight: null
    });
    const [inEditMode, setInEditMode] = useState(false);

    const {t, i18n} = useTranslation();

    useEffect(() => {
        if (props.scanRevision.measurement == null){
            setSavedDimensions({
                width: '',
                height: '',
                length: '',
                weight: '',
            });
        } else{
            setSavedDimensions({
                width: Math.round(props.scanRevision.measurement.width),
                height: Math.round(props.scanRevision.measurement.height),
                length: Math.round(props.scanRevision.measurement.length),
                weight: Math.round(props.scanRevision.measurement.weightCalc),
            });
        }

    }, [props.id]);

    const edit = () => {
        setInEditMode(true);
    }

    const save = () => {
        setInEditMode(false);
    }

    const cancel = () => {
        setSavedDimensions({...props.scanRevision.measurement});
        setInEditMode(false);
    }

    // const renderButtons = () => {
    //     if (inEditMode) {
    //         return (<div>
    //             <Button
    //                 onClick={() => {
    //                     cancel()
    //                 }}
    //                 color="danger">
    //                 <i className="fa fa-times"/>
    //                 {t('Cancel')}
    //             </Button>
    //             <Button
    //                 onClick={() => {
    //                     save();
    //                 }}
    //                 color="success">
    //                 <i className="fa fa-check"/> {t('Save')}
    //             </Button>
    //         </div>)
    //
    //     } else {
    //         return (<div>
    //             <Button
    //                 style={{marginLeft: '10px'}}
    //                 onClick={() => {
    //                     cancel()
    //                 }}>
    //                 {t("Reset")}
    //             </Button>
    //             <Button
    //                 onClick={() => {
    //                     edit();
    //                 }}
    //                 color="primary">
    //                 <i className="fa fa-edit"/> {t('Edit')}
    //             </Button>
    //         </div>)
    //     }
    // }

    const handleChange = (event) => {
        const {name, value} = event.target;
        setSavedDimensions((prevState) => ({...prevState, [name]: value}));
    }

    if (!props.scanRevision) {
        return (<div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle
                                tag="h4">{t('Loading')} {t('Measurements')} ...</CardTitle>
                        </CardHeader>
                        <CardBody/>
                    </Card>
                </Col>
            </Row>
        </div>)
    } else {
        return (<div className={"dimensions"}>
            <Table borderless>
                <tbody>
                <tr>
                    <td>
                        <p>{t('Width')}</p>
                    </td>
                    <td>
                        <Input
                            name="width"
                            disabled={!inEditMode}
                            value={savedDimensions.width}
                            onChange={handleChange}/>
                        <span>mm</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{t('Height')}</p>
                    </td>
                    <td>
                        <Input
                            name="height"
                            disabled={!inEditMode}
                            value={savedDimensions.height}
                            onChange={handleChange}/>
                        <span>mm</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{t('Length')}</p>
                    </td>
                    <td>
                        <Input
                            name="length"
                            disabled={!inEditMode}
                            value={savedDimensions.length}
                            onChange={handleChange}/>
                        <span>mm</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{t('Weight')}</p>
                    </td>
                    <td>
                        <Input
                            name={"weight"}
                            disabled={!inEditMode}
                            value={savedDimensions.weight}
                            onChange={handleChange}/>
                        <span>g</span>
                    </td>
                </tr>
                </tbody>
            </Table>
            {/*{renderButtons()}*/}
        </div>)
    }

}

export default MeasurementsEditor;
