import React, {useState, Suspense} from "react";

import apiClient, {baseURL} from './services/apiService';
import { useApolloClient } from "@apollo/client";

import {Switch, Route, BrowserRouter} from "react-router-dom";

import Login from '../src/views/forms/Login'
import ProductsTable from "./components/Product/ProductsTable";
import ProductDetails from './components/Product/ProductDetails'

import GuardedRoute from './components/Route/GuardedRoute'
import Unprotected from './views/Unprotected'

import { useIdleTimer } from 'react-idle-timer'

const App = () => {

    const [loggedIn, setLoggedIn, setUser] = useState(
        sessionStorage.getItem('loggedIn') === 'true' || false
    );

    const onIdle = () => {
        // Close Modal Prompt
        // Do some idle action like log out your user

        // reload page if user is idle for one hour
        window.location.reload(false);

        // uncomment for logout instead of page refresh
        // logout();
    }



    const onActive = (event) => {
        // Close Modal Prompt
        // Do some active action
    }

    const idleTimer = useIdleTimer({ timeout: 60 * 1000 * 60, onIdle, onActive })

    const client = useApolloClient();

    const login = () => {
        setLoggedIn(true);
        sessionStorage.setItem('loggedIn', 'true');

        clearApolloCache();


    };




    const logout = () => {
        apiClient.post(`${baseURL}logout`).then(response => {
            if (response.status === 204) {
                setLoggedIn(false);
                sessionStorage.setItem('loggedIn', 'false');

                clearApolloCache();

                window.location.href = "/login";
            }
        })
    };

    // test api connection and logout if session expired (status is 419)
    // TODO: add matching query (returning a status text)
    // TODO: handle error in actual requests
    /* apiClient.post(`${baseURL}graphql`).catch(error => {
        if (error.response.status === 419) {
            setLoggedIn(false);
            sessionStorage.setItem('loggedIn', 'false')
            clearApolloCache();
        }
    }); */

    const clearApolloCache = () => {
        // clear all cached queries (regarding the apollo client)
        client.clearStore().then(() => {
            client.resetStore();
        });
    }

    if (!loggedIn) {
        return (
            <BrowserRouter>
                <Route exact={true} path="*" render={props => <Login {...props} login={login} />}/>
            </BrowserRouter>
        );
    } else {
        return (
            <BrowserRouter>
                <Switch>
                    <GuardedRoute path='/' component={ProductsTable} auth={loggedIn} logout={logout}/>
                    <GuardedRoute path="/admin/product/:uuid" component={ProductDetails}/>

                    {/*User will LogIn*/}
                    <Route path='/login' render={props => (
                        <Login {...props} login={login}/>
                    )}/>

                    <Route path='/unprotected' component={Unprotected}/>
                    {/*Page Not Found*/}
                </Switch>
            </BrowserRouter>

        );
    }

};
export default App;
