import React from "react";
import axios from 'axios';

let baseURL = `https://schwarz-api.access.kaptura.de/`;

const apiClient = axios.create({
    baseURL: `https://schwarz-api.access.kaptura.de/`,
    withCredentials: true,
});

export default apiClient;
export {baseURL};
