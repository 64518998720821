/*eslint-disable*/
import React, {useEffect, useReducer, useState} from "react";
import {useFilters, useFlexLayout, usePagination, useSortBy, useTable} from "react-table";
import classnames from "classnames";
import "../../assets/css/kap-style.css";
// A great library for fuzzy filtering/sorting items
import {matchSorter} from "match-sorter";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

import {useTranslation} from 'react-i18next';
import ProductsFilter from "../Product/ProductsFilter";
import {FormGroup, Input} from "reactstrap";

// Define a default UI for filtering
function DefaultColumnFilter({column: {filterValue, preFilteredRows, setFilter}}) {
    const count = preFilteredRows.length;
    const {t} = useTranslation();

    return (
        <FormGroup>
            <Input
                type="text"
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                placeholder={t('Search Records', {no: count})}
            />
        </FormGroup>
    );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, {keys: [(row) => row.values[id]]});
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({
                   columns,
                   data,
                   paginator,
                   loading,
                   getTdProps = props => props,
                   // use server side processing if fetchData is defined
                   // or client side sorting / filtering if not
                   fetchData,
                   fetchMore,
                   pageCount: controlledPageCount,
                   showSkuFilter
               }) {

    const [numberOfRows, setNumberOfRows] = React.useState(10);
    const [pageSelect, handlePageSelect] = React.useState(0);

    // state management for sku filter (if in use)
    const [sku, setSku] = useState('');
    const [useSkuFilter, setUseSkuFilter] = useState(false);
    const onClickFilterCallback = (skuFilter) => {
        setUseSkuFilter(true)
        setSku(skuFilter)
        gotoPage(0)
    }

    const {t, i18n} = useTranslation();

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    // only works on string comparison (dates specified in us format, so year is first, followed by month and time)
    // const sortess = React.useMemo(
    //     () => [
    //
    //         {
    //             id: "date-finished", //sort product details table 'alle scans' data in descending order
    //             desc: true
    //         }
    //     ],
    //     []
    // );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            // use custom text fields for filters if server-side filtering is enabled
            // TODO: use column filters in fetch request when doing server-side sorting
            Filter: fetchData ? () => <div/> : DefaultColumnFilter,
            // CHANGED
            // using flex layout for 100% table width (and growing columns)
            // see https://react-table-v7.tanstack.com/docs/api/useFlexLayout#example-full-width-resizable-table
            minWidth: 30, // minWidth is only used as a limit for resizing
            width: 150, // width is used for both the flex-basis and flex-grow
            maxWidth: 250, // maxWidth is only used as a limit for resizing

        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        visibleColumns,
        nextPage,
        pageOptions,
        pageCount,
        previousPage,
        canPreviousPage,
        canNextPage,
        setPageSize,
        gotoPage,
        state: {pageIndex, pageSize, sortBy, columnFilters, globalFilter},
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            autoResetPage: false,
            manualPagination: fetchData ? true : false,
            autoResetGlobalFilter: false,
            manualFiltering: showSkuFilter ? true : false,
            pageCount: controlledPageCount,
            manualSortBy: fetchData ? true : false,
            // defaultCanSort: true,
            initialState: {
                pageIndex: 0,
                pageSize: 10,
                // sortBy: sortess,
                // hide column if isVisible is set to false
                hiddenColumns: columns.filter((column) => column.isVisible === false).map((column) => column.id)
            }
        },
        useFilters, // useFilters!
        useSortBy,
        usePagination,
        // CHANGED
        // using flex layout for 100% table width (and growing columns)
        // see https://react-table-v7.tanstack.com/docs/api/useFlexLayout#example-full-width-resizable-table
        useFlexLayout
    );

    // enable server-side hooks if fetch function is defined
    if (fetchData) {
        useEffect(() => {
            fetchData({pageIndex, pageSize, sortBy, sku});
        }, [fetchData, pageIndex, pageSize, sortBy, sku ])
    }

    let pageSelectData = Array.apply(
        null,
        Array(pageOptions.length)
    ).map(function () {
    });
    let numberOfRowsData = [5, 10, 20, 25, 50, 100];
    return (
        <>
            <div className="ReactTable -striped -highlight">

                {/* <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                /> */}

                {showSkuFilter &&
                    <div className="col-md-12 col-lg-12">
                        <ProductsFilter onClickFilterCallback={onClickFilterCallback} defaultKeyword={sku}/>
                    </div>
                }

                <div className="pagination-top"/>
                <table {...getTableProps()} className="rt-table">
                    <thead className="rt-thead -header">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                            {headerGroup.headers.map((column, key) => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className={classnames("rt-th rt-resizable-header", {
                                        // TODO: check didn't work for last column, understand and correct it
                                        // old code: headerGroup.headers.length - 1 instead of true
                                        "-cursor-pointer": true,
                                        "-sort-asc": column.isSorted && !column.isSortedDesc,
                                        "-sort-desc": column.isSorted && column.isSortedDesc,
                                    })}
                                >
                                    {/* TODO: move instance specific column names to props (e.g. which columns should get a class?) */}
                                    <div className={`rt-resizable-header-content
                                    ${(column.id === 'date-finished' ||
                                        column.id === 'mode' ||
                                        column.id === 'preset' ||
                                        column.id === 'upload-to-ftp' ||
                                        column.id === 'upload-to-shop') ? column.id : ''}`}
                                    >
                                        {column.render("Header")}
                                    </div>
                                    {/* Render the columns filter UI */}
                                    <div>
                                        {   // TODO: check didn't work for last column, understand and correct it
                                            /* headerGroup.headers.length - 1 === key
                                            ? null
                                            : */
                                            column.canFilter
                                                ? column.render("Filter")
                                                : null
                                        }
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="rt-tbody">
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className={classnames(
                                    "rt-tr",
                                    {" -odd": i % 2 === 0},
                                    {" -even": i % 2 === 1}
                                )}
                            >

                                {row.cells.map((cell) => {
                                    // console.log(cell.column.id);
                                    return (
                                        <td {...cell.getCellProps()} {...getTdProps(cell)}
                                            className={`rt-td ${cell.column.id}`}>
                                            {cell.render("Cell")}
                                        </td>
                                    );


                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                <div className="pagination-bottom">
                    <div className="-pagination" id="pagination_inline">
                        <div className="-previous p_button">
                            <button
                                type="button"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                                className="-btn" id="p_button"
                            >
                                <span className="previous_span">{t('Previous')}</span>
                            </button>
                        </div>
                        <div className="-center flex-nowrap">
                            <Select
                                className="react-select info mx-5 w-100 page-select"
                                classNamePrefix="react-select"
                                name="singleSelect"
                                value={{label: t('Page') + " " + (pageIndex + 1), value: pageIndex}}

                                onChange={(value) => {
                                    gotoPage(value.value);
                                }}

                                options={pageSelectData.map((prop, key) => {
                                    return {
                                        value: key,
                                        label: t('Page') + " " + (key + 1),
                                    };

                                })}

                                placeholder={t('Select page')}
                            />
                            <span className={"span-with-page-count"}> {t('of')} {pageCount}</span>
                            <Select
                                className="react-select info mx-5 w-100 row-select"
                                classNamePrefix="react-select"
                                name="singleSelect"
                                value={numberOfRows}
                                onChange={(value) => {
                                    // console.log(value);
                                    setPageSize(value.value);
                                    setNumberOfRows(value);
                                }}
                                options={numberOfRowsData.map((prop) => {
                                    return {
                                        value: prop,
                                        label: prop + " " + t('Rows'),
                                    };
                                })}

                                placeholder={t('Select #rows')}
                            />
                        </div>
                        <div className="-next button">
                            <button
                                type="button"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                                className="-btn n_button" id="n_button"
                            >
                                <span className="next_span">{t('Next')}</span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;
