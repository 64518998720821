import React, {useEffect, useMemo, useState} from "react";

// react component for creating dynamic tables
import Table from "../ReactTable/ReactTable";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    // FormGroup,
    // Input,
    // Label,
    // Table
} from "reactstrap";

import {useTranslation} from "react-i18next";

import apiClient from "../../services/apiService";
import moment from 'moment/min/moment-with-locales';
import { baseURL } from "services/apiService";

function ScanInfo(props) {
    // console.log(props)

    // date pattern returned from the api
    const datePattern = 'YYYY-MM-DD hh:mm:ss';

    // compare 2 arbitrary objects
    const compare = (a, b) => (a > b) - (a < b)

    // parse dates and compare them
    const compareDates = (a, b) => {
        return compare(moment(a, datePattern), moment(b, datePattern));
    }

    // scan selected by the user
    // select latest scan on load
    const [selectedScan, setSelectedScan] = useState(
        [...props.product.scans].sort(
            function (a, b) {
                return compareDates(a.finished, b.finished);
            }
        ).pop()
    );

    const {t, i18n} = useTranslation();

    const columns = useMemo(() =>
        [
            {
                id: 'date-finished',
                Header: t("Date"),
                accessor: 'finished',
                Cell: (props) => props.value,
                width: "auto",
                disableFilters: true,
            },
            {
                id: 'mode',
                Header: t("Mode"),
                accessor: 'mode',
                width: 'auto',
                disableFilters: true,
            },
            {
                id: 'preset',
                Header: t("Preset"),
                accessor: 'preset',
                width: "auto",
                disableFilters: true,
               
            },
            {
                id: 'download',
                Header: "Herunterladen",
                accessor: row => (
                    <a href={`${baseURL}cache/download/${row.id}`}>
                    <Button className={'uploadBtn'}>
                        Herunterladen
                    </Button>
                    </a>
                ),
                width: "auto",
                disableFilters: true,
                isVisible: true,
            },
            /* {
                id: 'upload-to-ftp',
                Header: "Upload",
                accessor: row => (
                    <Button className={'uploadBtn'}
                            onClick={(e) => handleUpload(row.id, 'sftp_k4r', 'upload')}>
                        FTP<i className="tim-icons icon-upload"/>
                    </Button>
                ),
                width: "auto",
                disableFilters: true,
                isVisible: false,
            },
            {
                id: 'upload-to-shop',
                Header: "Upload",
                accessor: row => (
                    <Button className={'uploadBtn'}
                            onClick={(e) => handleUpload(row.id, 'sftp_k4r', 'export')}>
                        Shop<i className="tim-icons icon-upload"/>
                    </Button>
                ),
                width: "auto",
                disableFilters: true,
                isVisible: false,
            }, */
            {
                id: 'uuid',
                Header: "",
                accessor: 'id',
                isVisible: false,
                disableFilters: true
            }
        ], [props.product.id, i18n.language]
    );

    // console.log(columns);


    /* const handleUpload = (uuid, destination = 'sftp_k4r', action = 'upload') => {
        let url = `/actions/scans/${action}/${uuid}/to/${destination}`;

        apiClient.post(url)
            .then((response) => {
            //  console.log(`${action} called with response:`);
             //   console.log(response);
            })
    } */

    if (!props.product) {
        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle
                                    tag="h4">{t('Loading')} {t('Scans')} ...</CardTitle>
                            </CardHeader>
                            <CardBody/>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    } else {
        return (
            <div className="content scan-info">
                <Row>
                    <Col md="12">
                        <Table
                            getTdProps={(cell) => {
                                return {
                                    onClick: (e, handleOriginal) => {
                                        setSelectedScan(cell.row.original);
                                        props.onClick(cell.row.original.id)

                                        if (handleOriginal) {
                                            handleOriginal()
                                        }
                                    },
                                    style: {
                                        background: cell.row.original.id === selectedScan.id ? '#525f7f' : '#27293d',
                                    }
                                }
                            }}

                            columns={columns}
                            data={props.product.scans}
                            showSkuFilter={false}
                        />
                    </Col>
                </Row>
            </div>
        )
    }

}

export default ScanInfo;
