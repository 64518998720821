import {React, useMemo, useState} from "react";

// reactstrap components
import {Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Row} from "reactstrap";
import Table from '../ReactTable/ReactTable'

import {useHistory} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import '../../assets/css/kap-style.css'
import {baseURL} from '../../services/apiService'

// Import everything needed to use the `useQuery` hook
import {gql, useQuery} from '@apollo/client';

import moment from 'moment/min/moment-with-locales';

const GET_PRODUCTS = gql`
    query GetProducts($first: Int!, $page: Int!, $column: QueryProductsOrderByColumn!, $order: SortOrder!, $sku: String) {
        products(first: $first, page: $page, orderBy: [
            {
                column: $column
                order: $order
            }
        ], sku: $sku) {
            data {
                id
                skus
                latest_scan_length
                latest_scan_width
                latest_scan_height
                latest_scan_weight
                latest_scan_finished_at
                latest_image_path
            }
            paginatorInfo {
                currentPage
                lastPage
                hasMorePages
            }

        }
    }
`;

function ProductsTable(props) {

    const order = useState('DESC');
//        const pageSize = useState(10);

    const fetchData = ({ pageIndex, pageSize, sortBy, sku }) => {
        let order = 'DESC'; // Default order
        let column = 'LATEST_SCAN_FINISHED_AT'; // Default Column
        if (sortBy && sortBy.length > 0) {
            // Set order based on the value of sortBy
            const sortItem = sortBy[0]; // Assume only one sort item for simplicity
            order = sortItem.desc ? 'DESC' : 'ASC';

            //Set Column name according to header ID
            if (sortItem.id === 'skus') {
                column = 'SKUS';
            } else if (sortItem.id === 'length') {
                column = 'LATEST_SCAN_LENGTH';
            } else if (sortItem.id === 'width') {
                column = 'LATEST_SCAN_WIDTH';
            } else if (sortItem.id === 'height') {
                column = 'LATEST_SCAN_HEIGHT';
            } else if (sortItem.id === 'weight') {
                column = 'LATEST_SCAN_WEIGHT';
            }

        }

        let variables = {
            first: pageSize,
            page: pageIndex + 1, // start from the indexToFetch-th item
            column: column,
            order: order
        }

        if (sku !== '') {
            variables = {...variables, sku: sku};
        }

        fetchMore({
            variables
        }).then((result) => {
            console.log('requested data is');
            console.log(result);
        });

    };

    const {loading, data, error, fetchMore} = useQuery(GET_PRODUCTS, {
        variables: {
            first: 10,
            page: 1,
            column: 'LATEST_SCAN_FINISHED_AT',
            order: 'ASC',
        }
    });

    const {t, i18n} = useTranslation();

    const history = useHistory();
    const navigateToArticle = (uuid) => history.push(`/admin/product/${uuid}`);

    const columns = useMemo(() => {
        const datePattern = 'YYYY-MM-DD hh:mm:ss';

        let columns = [
            {
                Header: "Auswahl",
                id: "check",
                accessor: row => (
                    <div>
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox"/>
                                <span className="form-check-sign"/>
                            </Label>
                        </FormGroup>
                    </div>
                ),
                disableSortBy: true,
                disableFilters: true,
                width: 35
            },
            {
                Header: t("Img"),
                id: "image",
                accessor: 'latest_image_path',
                Cell: props => (
                    <div className="img-container">
                        {props.value && <img src={`${baseURL}cache/` + props.value} alt=""/>}
                    </div>
                ),
                disableSortBy: true,
                disableFilters: true,
            },
            {
                Header: t("Article"),
                id: "skus",
                accessor: 'skus',
                Cell: (props) => props.value[0],
                sortType: (a, b, columnId, desc) => compare(a.values[columnId], b.values[columnId])
            },
            {
                Header: t("Length"),
                id: "length",
                accessor: "latest_scan_length",
                Cell: (props) => (props.value ? Math.round(props.value) : 0) + ' mm',
                sortType: (a, b, columnId, desc) => compareFloats(a.values[columnId], b.values[columnId])
            },
            {
                Header: t("Width"),
                id: "width",
                accessor: "latest_scan_width",
                Cell: (props) => (props.value ? Math.round(props.value) : 0) + ' mm',
                sortType: (a, b, columnId, desc) => compareFloats(a.values[columnId], b.values[columnId])
            },
            {
                Header: t("Height"),
                id: "height",
                accessor: "latest_scan_height",
                Cell: (props) => (props.value ? Math.round(props.value) : 0) + ' mm',
                sortType: (a, b, columnId, desc) => compareFloats(a.values[columnId], b.values[columnId])
            },
            {
                Header: t("Weight"),
                id: "weight",
                accessor: "latest_scan_weight",
                Cell: (props) => (props.value ? props.value : 0) + ' g',
                sortType: (a, b, columnId, desc) => compareFloats(a.values[columnId], b.values[columnId])
            },
            {
                Header: t("Date"),
                id: "date",
                accessor: "latest_scan_finished_at",
                Cell: (props) => moment(props.value, datePattern).locale('de').format('MMM D, YYYY, HH:mm') + ' Uhr',
                sortType: (a, b, columnId, desc) => compareDates(a.values[columnId], b.values[columnId])
            },
            {
                id: "uuid",
                accessor: "id",
                isVisible: false,
                width: 0
            }
        ];

        const compare = (a, b) => {
            return (a > b) - (a < b);
        }

        const compareFloats = (a, b) => {
            return compare(parseFloat(a), parseFloat(b));
        }

        const compareDates = (a, b) => {
            return compare(moment(a, datePattern), moment(b, datePattern));
        }

        return columns;
    }, [i18n.language]);

    if (error) {
        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">{t('Error')}: {error.message}</CardTitle>
                            </CardHeader>
                            <CardBody/>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    } else if (loading) {
        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle
                                    tag="h4">{t('Loading')} {t('Articles')} ...</CardTitle>
                            </CardHeader>
                            <CardBody/>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    } else {
        console.log("data is");
        console.log(data);

        return (
            <div className="content products-table">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">{t('Recent')} {t('Articles')}</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    getTdProps={(cell) => {
                                        return {
                                            onClick: (e, handleOriginal) => {
                                                if (cell.column.id !== 'check') {
                                                    navigateToArticle(cell.row.original.id);
                                                }

                                                if (handleOriginal) {
                                                    handleOriginal()
                                                }
                                            }
                                        }
                                    }}
                                    columns={columns}
                                    data={data.products.data}
                                    pageCount={data.products.paginatorInfo.lastPage}
                                    fetchData={fetchData}
                                    loading={loading}
                                    showSkuFilter={true}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ProductsTable;
