/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import ProductDetails from "./components/Product/ProductDetails";
import ProductsTable from "./components/Product/ProductsTable";

const routes = [

  {
    path: "/products",
    name: 'All Articles',
    // rtlName: "هعذاتسجيل الدخول",
    icon: "tim-icons icon-chart-pie-36",
    // rtlMini: "هعذا",
    component: ProductsTable,
    layout: "/admin"
  },
  {
    path: "/product/:uuid",
    name: "Selected Article",
    // rtlName: "هعذاتسجيل الدخول",
    icon: "tim-icons icon-zoom-split",
    // rtlMini: "هعذا",
    component: ProductDetails,
    layout: "/admin"
  }

];

export default routes;
