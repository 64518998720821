/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";

import "components/Multilingual/i18n"

import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from '@apollo/client';

import {setContext} from '@apollo/client/link/context';

import {baseURL} from "./services/apiService";

import App from './App';

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.2.0";
import "assets/demo/demo.css";

// XSRF token is required to make post requests to the backend
const authLink = setContext((_, {headers}) => {
    let token = RegExp('XSRF-TOKEN[^;]+').exec(document.cookie)
    token = decodeURIComponent(token ? token.toString().replace(/^[^=]+./, '') : '')
    return {
        headers: {
            ...headers,
            'X-XSRF-TOKEN': token,
        },
    };
});

const httpLink = createHttpLink({
    uri: `${baseURL}graphql`, // The graphql endpoint url
    credentials: 'include',
});

// Cache implementation
const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                products: {
                    keyArgs: false,
                },
            },
        },
    },
});

// A basic field policy that uses options.args.{page,first} to splice
// the incoming data into the existing array.
export function pageFirstPagination(
    keyArgs = false) {
    return {
        keyArgs,
        merge(existing, incoming, {args}) {
            console.log('existing is');
            console.log(existing);
            console.log('incoming is');
            console.log(incoming);

            const merged = existing ? existing.slice(0) : [];

            if (incoming) {
                if (args) {
                    // Assume an offset of 0 if args.offset omitted.
                    const {page = 0, first = 10} = args;
                    for (let i = 0; i < incoming.data.length; ++i) {
                        merged[((page - 1) * first) + i] = incoming.data[i];
                    }
                } else {
                    console.log('ERROR');

                    // It's unusual (probably a mistake) for a paginated field not
                    // to receive any arguments, so you might prefer to throw an
                    // exception here, instead of recovering by appending incoming
                    // onto the existing array.
                    merged.push.apply(merged, incoming.data);
                }
            }

            console.log('merged data is');
            console.log(merged);

            return merged;
        }
    };
}

// Create the apollo client
const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    connectToDevTools: true,
    defaultOptions: {watchQuery: {fetchPolicy: 'cache-and-network'}},
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <Suspense fallback="loading">
            <ApolloProvider client={client}>
                <App/>
            </ApolloProvider>
        </Suspense>
    </React.StrictMode>
);
