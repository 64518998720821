import React, {useEffect, useState} from 'react';
import apiClient, {baseURL} from "../../services/apiService";

// keep this import!
// it is needed for parsing the models (glb)
// a missing import will lead to parse errors at runtime
// but the compiler won't emit an error / a warning
import "@babylonjs/loaders/glTF";

import {Buffer} from 'buffer';

import {Engine, Scene} from 'react-babylonjs'
import {Vector3, Color3} from '@babylonjs/core'

// TODO: use AssetManager for switching between different models (scans)
import ScaledModelWithProgress from "../3D/ScaledModelWithProgress";

import {useTranslation} from "react-i18next";

function Babylon3DViewer(props) {

    // console.log(props);

    const [fetchResult, setFetchResult] = useState({error: null, isLoaded: false, dataURL: ""});
    const [tags, setTags] = useState({});

    const {t, i18n} = useTranslation();

    useEffect(() => {
        // retrieve model via axios because of authorization issues in react-babylonjs
        apiClient.get(`${baseURL}cache/` + props.scan.latestRevision.model, {responseType: 'arraybuffer'})
            .then((response) => {
                if (response.data) {
                    let base64 = Buffer.from(response.data, 'binary')
                        .toString('base64');

                    setFetchResult({...fetchResult, dataURL: "data:base64," + base64});
                }
            })
    }, [props.scan.id]);

    const onSceneMount = (e) => {
        let {canvas, scene, engine, box, v2} = e;

        /* don't propagate mouse movements to the parent components
            in order to avoid website scrolling while zooming in and out */
        canvas.addEventListener("wheel", evt => {
            evt.stopPropagation();
            evt.preventDefault()
        });
    }

    // removed ply based scene creation
    // from now on using glb as source for all models

    // render model when loaded
    if (fetchResult.dataURL) {
        return (
            <div className={"model"}>
                <Engine antialias adaptToDeviceRatio canvasId='babylonJS'>
                    <Scene onSceneMount={onSceneMount} disposeInstanceOnUnmount={true}>
                        <arcRotateCamera
                            name='camera1'
                            alpha={Math.PI / 2}
                            beta={Math.PI / 2}
                            radius={9.0}
                            target={Vector3.Zero()}
                            minZ={0.001}/>
                        <hemisphericLight
                            name='light1'
                            intensity={1}
                            direction={Vector3.Zero()}/>

                        <ScaledModelWithProgress
                            rootUrl={''}
                            sceneFilename={fetchResult.dataURL}
                            scaleTo={5}
                            center={new Vector3(0, 0, 0)}
                            progressBarColor={
                                Color3.FromInts(255, 165, 0)
                            }/>
                    </Scene>
                </Engine>
                {tags && tags.license && <br/>}
                {tags && tags.license && <p>{tags.license}</p>}
            </div>
        )
    } else {
        return null;
    }
}

export default Babylon3DViewer;